@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f3f4f7;
  /*background-color: aliceblue;*/
  /* background-image: linear-gradient(131deg, #ffff 30%, #e2f1ff 100%);
  background-repeat: no-repeat;
  background-size: cover; */
  font-family: "Poppins";
  font-weight: 400;
  /* border-bottom: lightgray solid 1px; */
  /* margin-top: 60px; */
  margin-bottom: 50px;
  --removed-scroll-width: 0px !important;
}

blockquote {
  border-left: #e1e6ec solid 2px;
}

.indicator {
  position: relative;
  display: inline-flex;
  width: max-content;
}

.indicator :where(.indicator-item) {
  right: 0;
  left: auto;
  top: 0;
  bottom: auto;
  --tw-translate-x: 50%;
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.indicator :where(.indicator-item) {
  z-index: 1;
  position: absolute;
}

.hidden-element {
  width: 0;
  height: 0;
}

.scroll-content {
  overflow-x: hidden;
  height: 85vh;
  overflow-y: scroll;
  padding-right: 15px;
}

.h-65-vh {
  height: 65vh;
}

.min-h-60-vh {
  min-height: 60vh;
}

.min-h-75-vh {
  min-height: 75vh;
}

.min-h-70-vh {
  min-height: 70vh;
}

.min-h-85-vh {
  min-height: 85vh;
}

.max-h-70-vh {
  max-height: 70vh;
}

.min-h-app-shell {
  min-height: 82vh;
}

@media only screen and (max-width: 768px) {
  .min-h-app-shell {
    min-height: 70vh;
  }
}

.tc-list-scroll-view {
  height: 87%;
  overflow-x: none;
  overflow-y: scroll;
}

.tc-map {
  height: 150px;
}

.h-10-perc {
  height: 10%;
}

.h-90-perc {
  height: 90%;
}

.scrollable {
  overflow-x: hidden;
  overflow-y: scroll;
}

.max-h-30-vh{
    max-height: 30vh;
}

.max-h-45-vh{
    max-height: 45vh;
}
